<template>
  <router-link class="s-link" v-bind="$attrs"><slot /></router-link>
</template>

<script>
export default {
  name: 'SLink',
}
</script>

<style lang="scss" scoped>
.s-link {
  display: inline-block;
  color: #06f;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

.s-link:hover {
  color: #09f;
  text-decoration: underline;
}
</style>
